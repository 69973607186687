import styles from '../styles/Notifications.css'
import * as Icon from 'react-feather';
import userAvatar from '../assets/avatar.jpg'
import PrimaryButton from '../components/PrimaryButton'
import Header from '../components/Header'

const Notifications = () => {

  return (
    <div className="notifications-page">
      <Header 
          goBack
          pageTitle="Notifications"
      />
    	<div className="container"> 
      	 {/*LIST OF NOTIFICATIONS*/}

          <div className="notification-row">

            <div className="left-aligned-content">
              <div className="notif-thumbnail">
                <img src="" />
              </div>
              <div className="notif-content">
                <p className="notif-title">
                  Notification Title
                </p>
                <p className="notif-body">
                  Notification Body
                </p>
              </div>
            </div>

            <div className="right-aligned-content">
              <span>12h</span>
              <div className="new-notif-indicator"></div>
            </div>
            
          </div>
    	</div>
    </div>
  );
}

export default Notifications;
