import styles from '../styles/AdminSidemenu.css'
import * as Icon from 'react-feather';
import { useEffect, useState, useRef } from "react";
import userAvatar from '../assets/avatar.jpg'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

const AdminSidemenu = () => {

  return (
    <div className="admin-sidemenu">
    	<div className="sidemenu-container">
    		<h3>Admin</h3>
    		<hr />

    		<div className="menu-list">
    			<ul className="list">
    				<li className="menu-item" style={{backgroundColor: 'transparent'}}>All Users</li>
    				<li className="menu-item" style={{backgroundColor: 'var(--black-10)'}}>Beta Users</li>
    			</ul>
    		</div>
    	</div>
    </div>
  );
}

export default AdminSidemenu;