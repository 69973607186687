import styles from '../styles/TertiaryButton.css'


const TertiaryButton = ({buttonLabel, type}) => {
  return (
    <button 
      className="tertiary-button"
      type={type}
      >
      {buttonLabel}
    </button>
  );
}

export default TertiaryButton;
