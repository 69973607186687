import styles from '../styles/CreateMerch.css';
import * as Icon from 'react-feather';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';

const CreateMerch = ( {isVisible, onClose, children}) => {

	// THUMBNAIL UPLOAD
	const [preview, setPreview] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const today = new Date();

	const handleThumbnailChange = (event) => {
	const thumbnail = event.target.files[0];
	if (thumbnail) {
		const reader = new FileReader();
			reader.onloadend = () => {
			setPreview(reader.result); // Set the thumbnail preview
		};
		  reader.readAsDataURL(thumbnail); // Read the thumbnail as a Data URL
		} else {
		  	setPreview(null); // Reset preview if no thumbnail
		}
	};

	if (!isVisible) return null;

	return(
		<div className="create-merch-overlay">
			<div className="create-merch-card" onClick={(e) => e.stopPropagation()}>
				{children}
				<div className="create-merch-header">
					<p className="overlay-title">New Merch</p>
					<div className="close-overlay-button" onClick={onClose}>
						<Icon.X color='#fff' size={18} />
					</div>
				</div>

				<hr />

				<form className="create-merch-form">
					<div className="input-fields-section">

						<div className="media-uploader form-field">
							<p  className="label"> merch Thumbnail </p>
							<div className="upload-thumbnail">
								{preview && (
								<div className="thumbnail-preview">
									<img className="image-preview" src={preview} alt="Image Preview" />
								</div>
								)}
								<div className="file-uploader">
									<div className="uploader-icon">
										<Icon.Image size={32} color="var(--acc-blue)"/>
									</div>
									<label for="track-thumbnail" className="clickable-label">Click here to upload an merch thumbnail</label>
									<input 
										id="track-thumbnail" 
										type="file" 
										onChange={handleThumbnailChange}
        								accept="image/*"
										style={{display: 'none'}}
									/>
								</div>

							</div>
							

						</div>

						<div className="form-merch-name form-field">
				            <label for="merch-name" className="label">Name</label>
				            <input
				            	id="merch-name"
								placeholder="merch name"
								required
								className="input-field"
				            />
				         </div>

				        <div className="form-merch-description form-field">
				            <label for="merch-description" className="label">Description</label>
				            <textarea 
				            	id="merch-description"
								className="post-content input-field"
								placeholder="merch description"
								rows="10"
								required
							/>
				        </div>

				        <div className="form-buttons">
							
							<PrimaryButton buttonLabel="Post" className="button" />
						</div>

					</div>
				</form>

			</div>
		</div>
	)
}

export default CreateMerch;