import React, { useEffect, useState } from 'react';
import { auth, db, storage } from '../Firebase'; // Firebase config (ensure storage is configured)
import { doc, getDoc, setDoc } from 'firebase/firestore'; // Firestore functions
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import userAvatar from '../assets/profile-placeholder.svg'; // Placeholder for user avatar
import styles from '../styles/EditProfileInformation.css';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import { openDefaultEditor } from '@pqina/pintura'; // Pintura editor import
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase Storage imports

// Register the plugins for FilePond
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginImageEdit);

const EditProfile = () => {
  const [userData, setUserData] = useState(null); // State to store user data
  const [inputNameValue, setInputNameValue] = useState('');
  const [inputUsernameValue, setInputUsernameValue] = useState('');
  const [inputBioValue, setInputBioValue] = useState('');
  const [inputLocationValue, setInputLocationValue] = useState('');
  const [loading, setLoading] = useState(false); // Loading state to avoid immediate re-fetch
  const [files, setFiles] = useState([]); // State to store uploaded files
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [imageUploaded, setImageUploaded] = useState(false); // State to check if an image is uploaded
  const [editedFile, setEditedFile] = useState(null); // State to store the edited file

  // Handle input changes
  const handleNameChange = (e) => setInputNameValue(e.target.value);
  const handleUsernameChange = (e) => setInputUsernameValue(e.target.value);
  const handleBioChange = (e) => setInputBioValue(e.target.value);
  const handleLocationChange = (e) => setInputLocationValue(e.target.value);

  useEffect(() => {
    
const fetchUserData = async (uid) => {
  try {
    const userDocRef = doc(db, 'users', uid); // Reference to user's document
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      
      // Check if the profilePic is a Firebase Storage path (e.g., "gs://...")
      if (userData.profilePic && userData.profilePic.startsWith('gs://')) {
        const storageRef = ref(storage, userData.profilePic);
        userData.profilePic = await getDownloadURL(storageRef); // Fetch the authenticated URL
      }

      setUserData(userData); // Store fetched data
      setInputNameValue(userData.userName || '');
      setInputUsernameValue(userData.handle || '');
      setInputBioValue(userData.bio || '');
      setInputLocationValue(userData.location || '');
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        fetchUserData(currentUser.uid); // Fetch data for authenticated user
      }
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  // Function to handle the Save operation
  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to avoid re-rendering issues
  
    try {
      if (auth.currentUser) {
        const uid = auth.currentUser.uid;
        const userDocRef = doc(db, 'users', uid); // Reference user's document
  
        console.log("Starting profile save operation for UID:", uid);
  
        // Check if an edited file is available
        if (editedFile) {
          console.log("Edited file detected:", editedFile.name);
          
          const storageRef = ref(storage, `Public/${uid}/${editedFile.name}`);
          
          console.log("Uploading file to Firebase Storage at path:", `Public/${uid}/${editedFile.name}`);
          await uploadBytes(storageRef, editedFile);
          
          const profilePicUrl = await getDownloadURL(storageRef);
          console.log("File uploaded successfully. Download URL:", profilePicUrl);
  
          // Update document in Firestore
          console.log("Updating Firestore document for UID:", uid);
          await setDoc(
            userDocRef,
            {
              userName: inputNameValue,
              handle: inputUsernameValue,
              bio: inputBioValue,
              location: inputLocationValue,
              profilePic: profilePicUrl, // Save the profile picture URL
            },
            { merge: true } // Merge with existing data
          );
  
          console.log("Firestore document updated successfully.");
          alert("Profile updated successfully!");
        } else {
          console.log("No edited file detected. Updating other fields only.");
          await setDoc(
            userDocRef,
            {
              userName: inputNameValue,
              handle: inputUsernameValue,
              bio: inputBioValue,
              location: inputLocationValue,
            },
            { merge: true }
          );
          console.log("Firestore document updated successfully (no profile picture).");
          alert("Profile updated successfully!");
        }
      } else {
        console.error("No authenticated user. Save operation aborted.");
        alert("User not authenticated. Please log in and try again.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Error updating profile. Please try again.");
    } finally {
      setLoading(false); // Turn off loading after update
      setShowModal(false); // Close modal after save
    }
  };

  // Function to open the Pintura editor
  const handleEdit = () => {
    if (files.length > 0) {
      const file = files[0].file;
      openDefaultEditor({
        src: URL.createObjectURL(file),
        imageReader: (file) => file,
        imageWriter: (result) => {
          setEditedFile(result); // Save the edited file to state
        },
      });
    }
  };

  const handleDone = async () => {
    try {
      if (auth.currentUser && files.length > 0) {
        const uid = auth.currentUser.uid;
        const fileFromPond = files[0].file; // Get the file from FilePond's state
        const filename = fileFromPond.name;
  
        console.log("Uploading image for UID:", uid, "Filename:", filename);
        
        const storageRef = ref(storage, `Public/${uid}/${filename}`);
        await uploadBytes(storageRef, fileFromPond);
        
        const downloadURL = await getDownloadURL(storageRef);
        console.log("Image uploaded successfully. Download URL:", downloadURL);
  
        const userDocRef = doc(db, 'users', uid);
        console.log("Updating Firestore document with profile picture URL for UID:", uid);
        await setDoc(
          userDocRef,
          { profilePic: downloadURL },
          { merge: true }
        );
  
        console.log("Firestore document updated successfully with new profile picture.");
        alert("Image uploaded successfully!");
  
        setUserData((prevData) => ({
          ...prevData,
          profilePic: downloadURL,
        }));
      } else {
        console.error("No file to upload or user not authenticated.");
        alert("No file to upload or user not authenticated.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Error uploading image. Please try again.");
    }
  };

  return (
    <div className="edit-profile-information">
      <form className="edit-profile-form" onSubmit={handleSave}>
        <div className="profile-mobile-userphoto">
          <img src={userData?.profilePic || userAvatar} className="header-avatar" alt="User Avatar" />
          
          {/* Button to open modal (Use a standard <button> to prevent form submission) */}
          <button
            type="button"
            className="change-photo-button"
            onClick={() => setShowModal(true)}
          >
            Change Photo
          </button>
        </div>

        <div className="input-fields-section">
          <div className="form-name form-field">
            <p className="label">NAME</p>
            <input
              placeholder="Your name"
              value={inputNameValue}
              onChange={handleNameChange}
              disabled={loading} // Disable inputs during save operation
            />
          </div>

          <div className="form-username form-field">
            <p className="label">USERNAME</p>
            <input
              placeholder="Your username"
              value={inputUsernameValue}
              onChange={handleUsernameChange}
              disabled={loading}
            />
          </div>

          <div className="form-bio form-field">
            <p className="label">BIO</p>
            <input
              placeholder="Tell us about yourself"
              value={inputBioValue}
              onChange={handleBioChange}
              disabled={loading}
            />
          </div>

          <div className="form-location form-field">
            <p className="label">LOCATION</p>
            <input
              placeholder="Enter location"
              value={inputLocationValue}
              onChange={handleLocationChange}
              disabled={loading}
            />
          </div>

          <div className="form-buttons">
            <div className="button">
              <SecondaryButton buttonLabel="Cancel" id="button" disabled={loading} />
            </div>

            <div className="button">
              <PrimaryButton buttonLabel="Save" id="button" disabled={loading} />
            </div>
          </div>
        </div>
      </form>

      {/* Modal for FilePond with Pintura */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <p className="title">Upload and Edit Profile Picture</p>
              <span className="close-button" onClick={() => setShowModal(false)}>&times;</span>
            </div>
            
            <FilePond
              files={files}
              onupdatefiles={(updatedFiles) => {
                setFiles(updatedFiles);
                if (updatedFiles.length > 0) setImageUploaded(true); // Show buttons if an image is uploaded
              }}
              allowMultiple={false}
              maxFiles={1}
              name="files"
              labelIdle='Drag & Drop your picture or <span class="filepond--label-action">Browse</span>'
              acceptedFileTypes={['image/*']}
              imagePreviewHeight={470}
              className="filepond"
            />
            
            {/* Conditionally render Edit and Save buttons after image upload */}
            {imageUploaded && (
              <div className="modal-buttons">
                <div className="button">
                  <button
                    className="edit-button"
                    onClick={handleEdit}
                  >
                    EDIT
                  </button>
                </div>
                
                <div className="button">
                  <button
                    onClick={handleDone}
                    buttonLabel="Done"
                  > DONE
                  </button>
                </div>
                
                
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProfile;