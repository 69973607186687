import styles from '../styles/Follows.css';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom'; 
import { doc, getDoc, updateDoc, arrayRemove, arrayUnion } from 'firebase/firestore';
import { auth, db, storage } from '../Firebase';
import { getDownloadURL, ref } from "firebase/storage";

import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

const Follows = ({ passedUID }) => {
  const [isFollowersActive, setIsFollowersActive] = useState(true);
  const [isFollowingActive, setIsFollowingActive] = useState(false);

  const [authFollowing, setAuthFollowing] = useState([]); // Users followed by the logged-in user
  const [viewFollowers, setViewFollowers] = useState([]);
  const [viewFollowing, setViewFollowing] = useState([]);

  const [authFollowingCount, setAuthFollowingCount] = useState(0);
  const [viewFollowersCount, setViewFollowersCount] = useState(0);
  const [viewFollowingCount, setViewFollowingCount] = useState(0);

  const authUser = auth.currentUser;

  const toggleView = () => {
    setIsFollowersActive((prev) => !prev);
    setIsFollowingActive((prev) => !prev);
  };

  const handleFollowToggle = async (targetUserId) => {
    if (!authUser) return;

    try {
      const loggedInUserRef = doc(db, 'users', authUser.uid);
      const targetUserRef = doc(db, 'users', targetUserId);

      if (authFollowing.some((user) => user.userId === targetUserId)) {
        // Unfollow: Remove from logged-in user's following and target user's followers
        await updateDoc(loggedInUserRef, {
          following: arrayRemove(targetUserId),
        });
        await updateDoc(targetUserRef, {
          followers: arrayRemove(authUser.uid),
        });

        setAuthFollowing((prev) => prev.filter((user) => user.userId !== targetUserId));
        setAuthFollowingCount((prev) => prev - 1);
        console.log(`Unfollowed user: ${targetUserId}`);
      } else {
        // Follow: Add to logged-in user's following and target user's followers
        await updateDoc(loggedInUserRef, {
          following: arrayUnion(targetUserId),
        });
        await updateDoc(targetUserRef, {
          followers: arrayUnion(authUser.uid),
        });

        setAuthFollowing((prev) => [
          ...prev,
          { userId: targetUserId, profilePic: '', userName: '', handle: '' },
        ]);
        setAuthFollowingCount((prev) => prev + 1);
        console.log(`Followed user: ${targetUserId}`);
      }
    } catch (error) {
      console.error('Error toggling follow state:', error);
    }
  };

  const fetchUserData = async (uid, setFollowers, setFollowing, setFollowersCount, setFollowingCount) => {
    try {
      const userDocRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setFollowersCount(userData.followers?.length || 0);
        setFollowingCount(userData.following?.length || 0);
        await fetchDetails(userData.followers || [], userData.following || [], setFollowers, setFollowing);
      } else {
        console.log('No such document for user:', uid);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchDetails = async (followerIds, followingIds, setFollowers, setFollowing) => {
    const fetchUserDetails = async (id) => {
      const userDocRef = doc(db, 'users', id);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const { userName, handle, profilePic } = userDoc.data();
        let profilePicURL = profilePic || '../assets/avatar.jpg';
        if (profilePic && profilePic.startsWith('gs://')) {
          const storageRef = ref(storage, profilePic);
          profilePicURL = await getDownloadURL(storageRef);
        }
        return { userName, handle, profilePic: profilePicURL, userId: id };
      }
      return null;
    };

    const followerDetails = await Promise.all(followerIds.map(fetchUserDetails));
    const followingDetails = await Promise.all(followingIds.map(fetchUserDetails));

    setFollowers(followerDetails.filter((detail) => detail !== null));
    setFollowing(followingDetails.filter((detail) => detail !== null));
  };

  useEffect(() => {
    const fetchLoggedInUserFollowing = async () => {
      if (authUser) {
        const userDocRef = doc(db, 'users', authUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const followingIds = userDoc.data().following || [];
          const followingDetails = await Promise.all(
            followingIds.map(async (id) => {
              const userDocRef = doc(db, 'users', id);
              const userDoc = await getDoc(userDocRef);
              if (userDoc.exists()) {
                const { userName, handle, profilePic } = userDoc.data();
                return { userId: id, userName, handle, profilePic };
              }
              return null;
            })
          );
          setAuthFollowing(followingDetails.filter(Boolean));
        }
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchLoggedInUserFollowing();
      }
    });

    if (passedUID) {
      fetchUserData(passedUID, setViewFollowers, setViewFollowing, setViewFollowersCount, setViewFollowingCount);
    }

    return () => unsubscribe();
  }, [passedUID]);

  useEffect(() => {
    console.log('authFollowing:', authFollowing);
    console.log('viewFollowers:', viewFollowers);
    console.log('viewFollowing:', viewFollowing);
  }, [authFollowing, viewFollowers, viewFollowing]);

  return (
    <div className="user-follows">
      <div className="follows-tabs">
        <div className="tab-item" onClick={toggleView}>
          <div className="count">
            <span className="count-number">{viewFollowersCount}</span>
            <span className="count-label">Followers</span>
          </div>
          <div className="tab-underline" style={{ backgroundColor: isFollowersActive ? 'var(--acc-blue)' : 'transparent' }}></div>
        </div>

        <div className="tab-item" onClick={toggleView}>
          <div className="count">
            <span className="count-number">{viewFollowingCount}</span>
            <span className="count-label">Following</span>
          </div>
          <div className="tab-underline" style={{ backgroundColor: isFollowingActive ? 'var(--acc-blue)' : 'transparent' }}></div>
        </div>
      </div>

      <div className="user-follows-list">
        {(isFollowersActive ? viewFollowers : viewFollowing).map((user, index) => {
          const isFollowing = authFollowing.some((f) => f.userId === user.userId);

          return (
            <div key={index} className="user-follows-card">
              <div className="image-name">
                <div className="user-image-card">
                  <img src={user.profilePic} alt={user.userName} />
                </div>
                <div>
                  <Link
                    to={`/${user.handle}`}
                    onClick={() => console.log('Navigating to userID:', user.userId)}
                    style={{ textDecoration: 'none' }}
                  >
                    <p className="user-name-card">{user.userName}</p>
                  </Link>
                  <Link
                    to={`/${user.handle}`}
                    onClick={() => console.log('Navigating to userID:', user.userId)}
                    style={{ textDecoration: 'none' }}
                  >
                    <p className="user-handle-card">@{user.handle}</p>
                  </Link>
                </div>
              </div>
              <div className="button">
                {user.userId === authUser.uid ? (
                  <SecondaryButton buttonLabel="YOU" disabled />
                ) : isFollowing ? (
                  <SecondaryButton buttonLabel="UNFOLLOW" onClick={() => handleFollowToggle(user.userId)} />
                ) : (
                  <PrimaryButton buttonLabel="FOLLOW" onClick={() => handleFollowToggle(user.userId)} />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Follows;