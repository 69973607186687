import styles from '../styles/CreatePostPopup.css'
import * as Icon from 'react-feather';
import BubblePill from './BubblePill'
import PrimaryButton from './PrimaryButton'
import TertiaryButton from './TertiaryButton'
import userAvatar from '../assets/avatar.jpg'


const CreatePostPopup = ( {isVisible, onClose, children} ) => {

  if (!isVisible) return null;

  return (
    <div className="create-post-overlay" onClick={onClose}>
      <div className="overlay-content-web" onClick={(e) => e.stopPropagation()}>
        { children }
        <div className="container-header">
          <p>Write a post</p>
        </div>
        <textarea 
          className="post-content"
          placeholder="What's up?"
        />
        <div className="post-actions">
            <div className="add-content">
              <Icon.Image color='#0094D4' size={24} style={{margin: 'auto 0'}}/>
              <BubblePill bubbleText="+ Add Topic Bubble"/>
            </div>
            <PrimaryButton buttonLabel="POST"/>
          </div>
      </div>

      <div className="overlay-content-mobile" onClick={(e) => e.stopPropagation()}>
        { children }
        <div className="container-header">
          <button onClick={onClose}>
            <TertiaryButton buttonLabel="CANCEL"/>
          </button>
          <button >
            <PrimaryButton buttonLabel="POST"/>
          </button>
        </div>
        <div className="compose-section">
          <div className="compose-area">
            <div className="user-image">
              <img src={userAvatar} />
            </div>
            <textarea 
              className="post-content"
              placeholder="What's up?"
              rows="15"
            />
          </div>
          <hr />
          <div className="post-actions">
            <div className="add-content">
              <Icon.Image color='#0094D4' size={24} style={{margin: 'auto 0'}}/>
              <BubblePill bubbleText="+ Add Bubble"/>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default CreatePostPopup;
