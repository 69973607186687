import styles from '../styles/MessagePrompt.css'
import * as Icon from 'react-feather';
import { useEffect, useState, useRef } from "react";
import userAvatar from '../assets/avatar.jpg'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';

const MessagePrompt = ({promptTitle, promptBody, cancelBtn, okBtn, onClose}) => {

  return (
    <div className="message-prompt-modal">
    	<div className="prompt-container">
    		<h4 className="prompt-title-text">{promptTitle}</h4>

    		<div className="prompt-body-text">
    			<p>{promptBody}</p>
    		</div>
        <div className="prompt-buttons">
          {cancelBtn && (
          <div className="btn"  onClick={onClose}>
            <SecondaryButton buttonLabel="Cancel" />
          </div>
          )}
          <div className="btn">
            <PrimaryButton buttonLabel="Yes" />
          </div>
        </div>
    	</div>
    </div>
  );
}

export default MessagePrompt;