import styles from '../styles/SecondaryButton.css';

const SecondaryButton = ({ buttonLabel, type, onClick }) => { // Add onClick prop
  return (
    <div>
      <button 
        className="secondary-button"
        type={type}
        onClick={onClick} // Apply the onClick to the button element
      >
        {buttonLabel}
      </button>
    </div>
  );
};

export default SecondaryButton;