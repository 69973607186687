import { useEffect, useState } from "react";
import styles from '../styles/NewsfeedCard.css';
import BubblePill from './BubblePill';
import SinglePostPopup from './SinglePostPopup';
import MessagePrompt from './MessagePrompt';
import SharePostModal from './SharePostModal';
import profilePlaceholder from '../assets/profile-placeholder.svg';
import * as Icon from 'react-feather';
import { Link, useParams } from 'react-router-dom'; 
import { auth, db, storage } from '../Firebase';
import { collection, doc, query, where, getDocs, getDoc, setDoc, addDoc, deleteDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';

function NewsfeedCard({ isTrendingActive, isFollowingActive, trendingTopic }) {
  const [posts, setPosts] = useState([]);
  const [commentCounts, setCommentCounts] = useState({});
  const [likeCounts, setLikeCounts] = useState({});
  const [isLiked, setIsLiked] = useState({});
  const [popupVisibleForPost, setPopupVisibleForPost] = useState(null);
  const [commentContent, setCommentContent] = useState(''); 
  const [isSinglePostPopupVisible, setIsSinglePostPopupVisible] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [isPostOptionsVisible, setIsPostOptionsVisible] = useState(false);
  const [isMessagePromptVisible, setIsMessagePromptVisible] = useState(false);
  const [isSharePostModalVisible, setIsSharePostModalVisible] = useState(false);
  const [promptTitle, setPromptTitle] = useState("Default Title");
  const [promptBody, setPromptBody] = useState("Default Body");

  const togglePostOptionsModal = () => setIsPostOptionsVisible(!isPostOptionsVisible);
  const toggleSharePostModal = () => setIsSharePostModalVisible(!isSharePostModalVisible);

  const openSinglePostPopup = (post) => {
    setSelectedPost(post);
    setIsSinglePostPopupVisible(true);
  };

  const closeSinglePostPopup = () => {
    setIsSinglePostPopupVisible(false);
    setSelectedPost(null);
  };

  const openMessagePrompt = () => {
    setPromptTitle("Delete Post");
    setPromptBody("Are you sure you want to delete this message? Once deleted you won't be able to undo it.");
    setIsMessagePromptVisible(true);
  };

  const closeMessagePrompt = () => {
    setIsMessagePromptVisible(false);
  };

  const toggleLike = async (postId) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const likesRef = collection(db, 'posts', postId, 'likes');
        const likeDocRef = doc(likesRef, user.uid);
        const likeDoc = await getDoc(likeDocRef);
  
        if (likeDoc.exists()) {
          // If the user has already liked the post, remove their like
          await deleteDoc(likeDocRef);
        } else {
          // If the user hasn't liked the post, add their like
          await setDoc(likeDocRef, {
            userID: user.uid,
            postID: postId,
            timestamp: serverTimestamp(),
          });
        }
  
        // Fetch the updated like count
        fetchLikes(postId);
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  const addComment = async (postId) => {
    try {
      const user = auth.currentUser;
      if (user && commentContent.trim()) {
        const commentsRef = collection(db, 'posts', postId, 'comments');
        await addDoc(commentsRef, {
          userID: user.uid,
          postID: postId,
          timestamp: serverTimestamp(),
          content: commentContent,
        });
        setCommentContent('');
        setPopupVisibleForPost(null);
        fetchCommentCount(postId);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const fetchCommentCount = async (postId) => {
    try {
      const commentsRef = collection(db, 'posts', postId, 'comments');
      const commentsSnapshot = await getDocs(commentsRef);
      setCommentCounts((prevCounts) => ({
        ...prevCounts,
        [postId]: commentsSnapshot.size
      }));
    } catch (error) {
      console.error('Error fetching comment count:', error);
    }
  };

  const fetchLikes = (postId) => {
    const likesRef = collection(db, 'posts', postId, 'likes');
    return onSnapshot(likesRef, (snapshot) => {
      setLikeCounts((prevCounts) => ({
        ...prevCounts,
        [postId]: snapshot.size, // Update like count directly from Firestore
      }));
      setIsLiked((prevLikes) => ({
        ...prevLikes,
        [postId]: snapshot.docs.some((doc) => doc.data().userID === auth.currentUser?.uid),
      }));
    });
  };

  const fetchPostsWithUserHandles = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      let querySnapshot;
      if (isFollowingActive) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        const following = userDoc.exists() ? userDoc.data().following : [];

        if (following.length === 0) {
          setPosts([]);
          return;
        }

        const postsRef = collection(db, 'posts');
        const q = query(postsRef, where('userID', 'in', following));
        querySnapshot = await getDocs(q);
      } else if (isTrendingActive) {
        const postsRef = collection(db, 'posts');
        const q = query(postsRef, where('topics', 'array-contains', trendingTopic));
        querySnapshot = await getDocs(q);
      }

      const postsWithHandles = await Promise.all(
        querySnapshot.docs.map(async (postDoc) => {
          const postData = postDoc.data();
          const userID = postData.userID;

          const userDocRef = doc(db, 'users', userID);
          const userDoc = await getDoc(userDocRef);
          const userHandle = userDoc.exists() ? userDoc.data().handle : 'Unknown User';
          let userProfilePic = userDoc.exists() ? userDoc.data().profilePic : profilePlaceholder;

          if (userProfilePic && userProfilePic.startsWith('gs://')) {
            const profilePicRef = ref(storage, userProfilePic);
            userProfilePic = await getDownloadURL(profilePicRef);
          }

          fetchCommentCount(postDoc.id);
          const unsubscribeLikes = fetchLikes(postDoc.id);

          return {
            id: postDoc.id,
            unsubscribeLikes,
            ...postData,
            userHandle,
            userProfilePic,
          };
        })
      );

      setPosts(postsWithHandles);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  useEffect(() => {
    fetchPostsWithUserHandles();

    return () => {
      posts.forEach((post) => {
        if (post.unsubscribeLikes) {
          post.unsubscribeLikes();
        }
      });
    };
  }, [isFollowingActive, isTrendingActive, trendingTopic]);


  return (
    <div className="newsfeed-card component">
      {posts.length > 0 ? posts.map((post) => (
        <div key={post.id} className="newsfeed-card-container">
          <div className="user-image">
            <Link 
              to={{
                pathname: `/${post.userHandle}`  // Use post.userID dynamically in the URL
              }}
              onClick={() => console.log("Navigating to userID: ", post.userID)}
              style={{ textDecorationLine: 'none' }}
            >
              <img src={post.userProfilePic || profilePlaceholder} alt="User profile" />
            </Link>
          </div>
          <div className="post-content">
            <span className="time-ago">
              {post.timestamp?.toDate ? post.timestamp.toDate().toLocaleString() : post.timestamp}
            </span>
            <div className="post-more-icon" onClick={togglePostOptionsModal}>
              <Icon.MoreVertical size={20} />
            </div>
            {/* OPTIONS MODAL */}
            {isPostOptionsVisible && (
              <div className="post-options-modal">
                <ul className="post-options-list">
                  {/* HIDE DELETE & EDIT POST IF NOT currentUser */}
                  <li>
                    <Icon.Edit3 size={14} className="icon"/>
                    <span className="option-name">Edit Post</span>
                  </li>
                  <li onClick={() => openMessagePrompt()}>
                    <Icon.Trash size={14} className="icon"/>
                    <span className="option-name">Delete Post</span>
                  </li>
                  <li>
                    <Icon.UserPlus size={14} />
                    <span className="option-name">Follow @{post.userHandle || 'Unknown User'}</span>
                    
                  </li>
                  <li>
                    <Icon.Slash size={14} />
                    <span className="option-name">Block @{post.userHandle || 'Unknown User'}</span>
                  </li>
                  <li>
                    <span>💩</span>
                    <span className="option-name">Poop this post</span>
                  </li>
                </ul>
              </div>
            )}

            <Link 
              to={{
                pathname: `/${post.userHandle}`
              }}
              onClick={() => console.log("Navigating to userID: ", post.userID)}
              style={{ textDecorationLine: 'none' }}
            >
              <p className="user-handle">@{post.userHandle || 'Unknown User'}</p>
          </Link> 
            
            <p className="content-text">{post.content}</p>

            {post.mediaURL && (
              <div className="photo-grid">
                <img src={post.mediaURL} alt="Post media" />
              </div>
            )}

            <div className="pill">
              {/* dynamic bubbleText value */}
              <BubblePill bubbleText="artist" />
            </div>

            <div className="post-actions">
              <div className="post-data-count">
                <button className="comments-view" onClick={() => openSinglePostPopup(post)}>
                  <span>{commentCounts[post.id] || 0} Comments</span>
                </button>
                <span> • </span>
                <span>{likeCounts[post.id] || 0} Likes</span>
              </div>
              <div className="like-comment">
                <button onClick={() => toggleLike(post.id)} className="like-button">
                  <Icon.ThumbsUp 
                    size={21} 
                    fill={isLiked[post.id] ? '#1D599C' : 'none'} 
                    color={isLiked[post.id] ? '#0094D4' : '#fff'} 
                  />
                </button>
                <button onClick={() => setPopupVisibleForPost(post.id)} className="comment-button">
                  <Icon.MessageSquare size={22} aria-label="Comment On Post" />
                </button>
                <button onClick={toggleSharePostModal} className="share-button">
                  <Icon.Share2 size={22} aria-label="Share Post" />
                </button>
              </div>
            </div>

            {popupVisibleForPost === post.id && (
              <div className="comment-popup">
                <textarea
                  value={commentContent}
                  onChange={(e) => setCommentContent(e.target.value)}
                  placeholder="Write your comment..."
                />
                <button onClick={() => addComment(post.id)}>Submit</button>
                <button onClick={() => setPopupVisibleForPost(null)}>Cancel</button>
              </div>
            )}
          </div>
        </div>
      )) : <p className="no-result">No posts available</p>}
      
      {/* Include SinglePostPopup outside the map function */}
      {isSinglePostPopupVisible && selectedPost && (
        <SinglePostPopup 
          isVisible={isSinglePostPopupVisible} 
          onClose={closeSinglePostPopup} 
          post={selectedPost} 
        />
      )}

      {isMessagePromptVisible && (
        <MessagePrompt 
          isVisible={isMessagePromptVisible} 
          onClose={closeMessagePrompt}
          cancelBtn
          promptTitle={promptTitle}
          promptBody={promptBody}
        />
      )}

      {isSharePostModalVisible && (
        <SharePostModal 
          onClose={toggleSharePostModal}
        />
      )}
    </div>
  );
}

export default NewsfeedCard;