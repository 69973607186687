import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

// Find the root element
const rootElement = document.getElementById('root');

if (rootElement) {
  // Create and render the root if the element is found
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
} else {
  // Log an error if the root element is not found
  console.error("Root container not found in the DOM.");
}