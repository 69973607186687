import styles from '../styles/ForgotPassword.css';
import * as Icon from 'react-feather';
import userAvatar from '../assets/avatar.jpg';
import submergeLogo from '../assets/submerge-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import PrimaryButton from '../components/PrimaryButton';
import { auth } from '../Firebase'; // Import Firebase auth
import { sendPasswordResetEmail } from 'firebase/auth'; // Import the function to send password reset emails

const ForgotPassword = () => {
  const [email, setEmail] = useState(''); // State for email
  const [loading, setLoading] = useState(false); // State for loading state
  const [message, setMessage] = useState(''); // State for feedback message
  const navigate = useNavigate(); // Hook to programmatically navigate

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      await sendPasswordResetEmail(auth, email); // Trigger Firebase password reset email
      setMessage('Password reset email sent successfully. Check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setMessage(error.message || 'Failed to send password reset email.');
    } finally {
      setLoading(false);
    }
  };

  return ( 
    <div className="forgotpassword-page">
      <div className="container">
        <div className="upper-section">
          <div className="logo">
            <img className="submerge-logo" src={submergeLogo} alt="Submerge Logo" />
            <p className="tagline">UNDERGROUND MUSIC NETWORK</p>
          </div>
        </div>

        <div className="forgot-password-card">
          <div className="card-header">
            <h2 className="card-title">Forgot Password</h2>
            <p className="card-body">Please input your email address and we'll send you instructions on how to reset your password.</p>
          </div>

          {/* Feedback message */}
          {message && <p className="feedback-message">{message}</p>}

          {/* Password Reset Form */}
          <form className="forgot-password-form" onSubmit={handlePasswordReset}>
            <div className="input-fields-section">
              <div className="form-email form-field">
                <label className="label">EMAIL</label>
                <input
                  className="form-input"
                  type="email"
                  placeholder="you@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} // Update email state
                  required
                />
              </div>
            </div>

            <div className="form-buttons">
              <PrimaryButton 
                buttonLabel={loading ? "Sending..." : "Submit"} 
                className="button" 
                disabled={loading} 
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;