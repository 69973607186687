import styles from '../styles/CreateEvent.css';
import * as Icon from 'react-feather';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CreateEvent = ( {isVisible, onClose, children}) => {

	// THUMBNAIL UPLOAD
	const [preview, setPreview] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const today = new Date();

	const handleThumbnailChange = (event) => {
	const thumbnail = event.target.files[0];
	if (thumbnail) {
		const reader = new FileReader();
			reader.onloadend = () => {
			setPreview(reader.result); // Set the thumbnail preview
		};
		  reader.readAsDataURL(thumbnail); // Read the thumbnail as a Data URL
		} else {
		  	setPreview(null); // Reset preview if no thumbnail
		}
	};

	if (!isVisible) return null;


	return(
		<div className="create-event-overlay">
			<div className="create-event-card" onClick={(e) => e.stopPropagation()}>
				{children}
				<div className="create-event-header">
					<p className="overlay-title">Post an Event</p>
					<div className="close-overlay-button" onClick={onClose}>
						<Icon.X color='#fff' size={18} />
					</div>
				</div>

				<hr />

				<form className="create-event-form">
					<div className="input-fields-section">

						<div className="media-uploader form-field">
							<p  className="label"> Event Thumbnail </p>
							<div className="upload-thumbnail">
								{preview && (
								<div className="thumbnail-preview">
									<img className="image-preview" src={preview} alt="Image Preview" />
								</div>
								)}
								<div className="file-uploader">
									<div className="uploader-icon">
										<Icon.Image size={32} color="var(--acc-blue)"/>
									</div>
									<label for="track-thumbnail" className="clickable-label">Click here to upload an event thumbnail</label>
									<input 
										id="track-thumbnail" 
										type="file" 
										onChange={handleThumbnailChange}
        								accept="image/*"
										style={{display: 'none'}}
									/>
								</div>

							</div>
							

						</div>

						<div className="form-event-title form-field">
				            <label for="event-title" className="label">Title</label>
				            <input
				            	id="event-title"
								placeholder="Event Title"
								required
								className="input-field"
				            />
				         </div>

				        <div className="form-event-description form-field">
				            <label for="event-description" className="label">Description</label>
				            <textarea 
				            	id="event-description"
								className="post-content input-field"
								placeholder="Event description"
								rows="10"
								required
							/>
				        </div>

				        <div className="form-event-date form-field" style={{display: 'block'}}>
				            <p className="label">When will this happen?</p>
				            <div className="datepicker-container">
				            	<DatePicker
									className="input-field datepicker"
									selected={startDate}
									onChange={(date) => setStartDate(date)}
	        						dateFormat="dd MMM yyyy"
	        						minDate={today}
				            	/>
				            	<Icon.Calendar size={14} color="var(--acc-blue)" className="calendar-icon"/>
				            </div>
				        </div>

				        <div className="form-event-location form-field">
				            <label for="event-location" className="label">Location</label>
				            <input
				            	id="event-location"
								placeholder="Where will it happen?"
								required
								className="input-field"
				            />
				        </div>

				        <div className="form-event-location form-field">
				            <label for="event-ticket" className="label">Ticket Price (USD $)</label>
				            <input
				            	id="event-ticket"
								placeholder="Ticket Price"
								required
								className="input-field"
				            />
				            <p className="subtext">Leave empty if admission's free.</p>
				        </div>


				        <div className="form-buttons">
							
							<PrimaryButton buttonLabel="Post" className="button" />
						</div>

					</div>
				</form>
			</div>
		</div>
	)
}

export default CreateEvent;