import styles from '../styles/Header.css';
import * as Icon from 'react-feather';
import radioIcon from '../assets/radio-icon.svg';
import submergeLogo from '../assets/submerge-logo.png';
import { useEffect, useState, useRef } from "react";
import userAvatar from '../assets/avatar.jpg';
import NotificationPopup from '../components/NotificationPopup';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../Firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

const Header = ({ goBack, pageTitle, avatar, radioButton, showLogo, hasUnreadMessages: propHasUnreadMessages }) => {
  const [isNotifOpen, setIsNotifOpen] = useState(false);
  //const [hasUnreadMessages, setHasUnreadMessages] = useState(false); // State for unread messages
  const [localHasUnreadMessages, setLocalHasUnreadMessages] = useState(false);
  const [currentUser, setCurrentUser] = useState(null); // Track current user dynamically
  const contentRef = useRef(null);
  const navigate = useNavigate();

  // Handle navigation back
  const handleGoBack = () => {
    navigate(-1);
  };

  // Toggle notification modal
  const toggleNotifModal = () => {
    setIsNotifOpen((prevState) => !prevState);
  };

  // Close notification modal on outside click
  const handleClickOutside = (event) => {
    if (contentRef.current && !contentRef.current.contains(event.target)) {
      setIsNotifOpen(false);
    }
  };

  useEffect(() => {
    if (isNotifOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNotifOpen]);

  // Set up Firebase Auth listener for current user
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  // Fetch unread messages for the logged-in user
  useEffect(() => {
    const fetchUnreadMessages = async () => {
      if (!currentUser) {
        console.warn('No current user logged in.');
        return;
      }

      try {
        // Step 1: Get chat IDs for the logged-in user
        const chatsRef = collection(db, 'chats');
        const userChatsQuery = query(chatsRef, where('participants', 'array-contains', currentUser.uid));
        const chatSnapshot = await getDocs(userChatsQuery);
        const chatIds = chatSnapshot.docs.map((doc) => doc.id);

        if (chatIds.length === 0) {
          console.log('No chats found for the current user.');
          setLocalHasUnreadMessages(false);
          return;
        }

        // Step 2: Query messages for those chats
        const messagesRef = collection(db, 'messages');
        const unreadQuery = query(
          messagesRef,
          where('chatId', 'in', chatIds) // Query only messages for relevant chats
        );
        const unreadSnapshot = await getDocs(unreadQuery);

        // Check if any messages do not include the user's UID in isReadBy
        const hasUnread = unreadSnapshot.docs.some((doc) => {
          const isReadBy = doc.data().isReadBy || [];
          return !isReadBy.includes(currentUser.uid);
        });

        setLocalHasUnreadMessages(hasUnread);
      } catch (error) {
        console.error('Error fetching unread messages:', error);
      }
    };

    if (propHasUnreadMessages === undefined) {
      fetchUnreadMessages();
    } 
  }, [propHasUnreadMessages, currentUser]);

  const hasUnreadMessages = propHasUnreadMessages ?? localHasUnreadMessages

  return (
    <div className="header">
      <header className="header-mobile">
        <Link to={handleGoBack} style={{ display: radioButton ? 'block' : 'none' }}>
          <img src={radioIcon} alt="Radio Icon" />
        </Link>

        <Link to="/" style={{ display: goBack ? 'block' : 'none' }}>
          <Icon.ChevronLeft style={{ margin: 'auto' }} />
        </Link>

        <h5 style={{ margin: 'auto', textTransform: 'uppercase', textDecoration: 'none' }}>{pageTitle}</h5>

        <div className="right-buttons">
          <Link to="/notifications" className="notif-button-mobile">
            <Icon.Bell size={20} style={{ margin: 'auto' }} />
          </Link>
          <Link to="/messages" className="messages-button-mobile">
            <Icon.MessageCircle
              color={hasUnreadMessages ? 'var(--acc-blue)' : '#fff'}
              fill={hasUnreadMessages ? 'var(--main-blue)' : 'none'}
              size={20}
            />
          </Link>
          <img src={userAvatar} className="header-avatar" style={{ display: avatar ? 'block' : 'none' }} />
        </div>
      </header>

      <header className="header-web">
        <div className="live-radio-button" style={{ display: radioButton ? 'block' : 'none' }}>
          <img src={radioIcon} alt="Radio Icon" />
        </div>

        <Link to="/" style={{ display: goBack ? 'block' : 'none' }}>
          <Icon.ChevronLeft style={{ margin: 'auto' }} />
        </Link>

        <Link to="/" className="link">
          <img
            className="submerge-logo"
            src={submergeLogo}
            alt="Submerge Logo"
            style={{ display: showLogo ? 'block' : 'none', margin: '0 auto' }}
          />
          <h5 style={{ textTransform: 'uppercase', display: pageTitle ? 'block' : 'none' }}>
            {pageTitle}
          </h5>
        </Link>

        <div className="right-buttons-web" ref={contentRef}>
          <Link to="/messages" className="messages-button-mobile right-button">
            <Icon.MessageCircle
              color={hasUnreadMessages ? 'var(--acc-blue)' : '#fff'}
              fill={hasUnreadMessages ? 'var(--main-blue)' : 'none'}
              size={20}
            />
            {hasUnreadMessages && <span className="indicator"></span>}
          </Link>
          <div className="open-notif-modal right-button" onClick={toggleNotifModal}>
            <Icon.Bell size={20} />
            <span className="indicator"></span>
          </div>

          {isNotifOpen && (
            <div className="notification-popup-modal">
              <NotificationPopup />
            </div>
          )}
        </div>
      </header>
    </div>
  );
};

export default Header;