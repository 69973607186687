import styles from '../styles/TrackDetails.css';
import * as Icon from 'react-feather';
import trackPlaceholder from '../assets/track-placeholder.svg'
import { useEffect, useState } from "react";
import BubblePill from '../components/BubblePill'
import { Link } from 'react-router-dom';

const TrackDetails = ( {isVisible, onClose, children}) => {

	// THUMBNAIL UPLOAD
	const [preview, setPreview] = useState(null);

	if (!isVisible) return null;


	return(
		<div className="track-details-overlay" onClick={onClose}>
			<div className="track-details-card" onClick={(e) => e.stopPropagation()}>
				{children}
				<div className="track-details-content"> 
					<div className="track-thumbnail">
						<img src={trackPlaceholder} />
					</div>

					<div className="track-details">
						<h3> Track Title </h3>
						<div className="artist-list">
							<BubblePill bubbleText="Dylan Thomas" />
							<BubblePill bubbleText="Dylan Thomas" />
							<BubblePill bubbleText="Dylan Thomas" />
						</div>

						<p className="track-year"> 2009 </p>

						<p className="track-description">
							His palms are sweaty, knees weak, arms are heavy
							There's vomit on his sweater already, mom's spaghetti
							He's nervous, but on the surface, he looks calm and ready
							To drop bombs, but he keeps on forgetting
							What he wrote down, the whole crowd goes so loud
							He opens his mouth, but the words won't come out 
						</p>

						<div className="genres-list">
							<p className="genre-item">Main Genre</p>
							<p className="genre-item">, Sub Genre</p>
							<p className="genre-item">, Sub Sub-Genre</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TrackDetails;