import React, { useEffect, useState } from 'react';
import { auth, db } from '../Firebase'; // Ensure Firebase is configured correctly
import { doc, setDoc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore'; // Firestore functions
import { onAuthStateChanged } from 'firebase/auth'; // Firebase Auth functions
import styles from '../styles/Admin.css';
import AdminSidemenu from '../components/AdminSidemenu'
import PrimaryButton from '../components/PrimaryButton'

function Admin() {
  const [emailInput, setEmailInput] = useState(''); // State for the email input
  const [fileContent, setFileContent] = useState([]); // State for file content lines
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track user authorization
  const [loading, setLoading] = useState(true); // State to track loading
  const [currentUserUid, setCurrentUserUid] = useState(null); // State to track current user's UID
  const [uidsInField, setUidsInField] = useState([]); // State to track UIDs in the uids field

  useEffect(() => {
    // Function to check if the user is authorized
    const checkUserAuthorization = async (uid) => {
      try {
        // Reference to the 'Global' field in the 'admin' document
        const adminDocRef = doc(db, 'auth', 'admins');
        const adminDoc = await getDoc(adminDocRef);
  
        if (adminDoc.exists()) {
          const data = adminDoc.data();
          const globalUidsArray = data.Global || []; // Access the 'Global' field as an array
          console.log('Fetched Global array from Firestore:', globalUidsArray); // Debugging log
  
          setUidsInField(globalUidsArray);
  
          // Check if the user's UID exists in the Global array
          if (globalUidsArray.includes(uid)) {
            setIsAuthenticated(true);
          }
        } else {
          console.warn('No data found in Firestore under auth/admin.');
        }
      } catch (error) {
        console.error('Error fetching admin data:', error);
      } finally {
        setLoading(false); // Turn off loading state
      }
    };
  
    // Check authentication status and user authorization
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserUid(user.uid); // Store the authenticated user's UID
        checkUserAuthorization(user.uid); // Check if the authenticated user is an admin
      } else {
        setLoading(false); // Turn off loading state if not authenticated
      }
    });
  
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  // Handle email input change
  const handleEmailChange = (e) => setEmailInput(e.target.value);

  // Function to update Firebase with email input
  const handleEmailSubmit = async () => {
    if (!emailInput) {
      alert('Please enter a valid email.');
      return;
    }

    try {
      const authorizedUsersRef = doc(db, 'auth', 'authorizedUsers');
      
      // Add the email input to the 'beta' array
      await updateDoc(authorizedUsersRef, {
        beta: arrayUnion(emailInput)
      });
      
      alert('Email added successfully to authorizedUsers.');
      setEmailInput(''); // Clear input field
    } catch (error) {
      console.error('Error updating authorizedUsers:', error);
      alert('Error updating authorizedUsers. Please try again.');
    }
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'text/plain' || file.type === 'text/csv')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        // Split file content by lines
        const lines = event.target.result.split('\n').map(line => line.trim()).filter(line => line);
        setFileContent(lines);
      };
      reader.readAsText(file);
    } else {
      alert('Please upload a .txt or .csv file.');
    }
  };

  // Function to update Firebase with file content
  const handleFileSubmit = async () => {
    if (fileContent.length === 0) {
      alert('No valid content to upload.');
      return;
    }

    try {
      const authorizedUsersRef = doc(db, 'auth', 'authorizedUsers');
      
      // Add each email from the file content to the 'beta' array
      for (const email of fileContent) {
        await updateDoc(authorizedUsersRef, {
          beta: arrayUnion(email)
        });
      }
      
      alert('File content added successfully to authorizedUsers.');
      setFileContent([]); // Clear file content state
    } catch (error) {
      console.error('Error updating authorizedUsers:', error);
      alert('Error updating authorizedUsers. Please try again.');
    }
  };

  // Render loading message while fetching data
  if (loading) {
    return <div>Loading...</div>;
  }

  // Render the Admin component only if the user is authenticated and authorized
  if (!isAuthenticated) {
    return (
      <div>
        <div>You do not have access to this page.</div>
        <div>Authenticated UID: {currentUserUid}</div>
        <div>Admin UIDs in Database: {uidsInField.join(', ')}</div>
      </div>
    );
  }

  return (
    <div className="admin">
      <AdminSidemenu />

      <div className="admin-container">
        <h3 className="admin-title">Beta Authorizations</h3>
        <div className="auth-container">
          {/*<h2 className="auth-h2">Beta Authorizations</h2>*/}
          {/* Input Email Field */}
          <div className="input-email-section">
            <h3>Input Email</h3>

            <div className="form">
              <input
                type="email"
                value={emailInput}
                onChange={handleEmailChange}
                placeholder="Enter email to authorize"
              />
              <div className="button">
                <PrimaryButton onClick={handleEmailSubmit} buttonLabel="Add Email" />
              </div>
            </div>
            
          </div>

          {/* File Upload Section */}
          <div className="file-upload-section">
            <h3>Upload .txt or .csv File</h3>
            <div className="form">
              <input
                type="file"
                accept=".txt, .csv"
                onChange={handleFileChange}
              />
              <div className="button">
                <PrimaryButton onClick={handleFileSubmit} buttonLabel="Upload File" />
              </div>
            </div>
            
          </div>   
        </div>

        <h4 className="admin-title">All Beta Users List</h4>

      </div>
    </div>
  );
}

export default Admin;