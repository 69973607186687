import styles from '../styles/SharePostModal.css'
import * as Icon from 'react-feather';
import { useEffect, useState, useRef } from "react";
import userAvatar from '../assets/avatar.jpg'
import messageIcon from '../assets/message-icon.svg'
import repostIcon from '../assets/repost-icon.svg'
import quotepostIcon from '../assets/quotepost-icon.svg'
import copylinkIcon from '../assets/copylink-icon.svg'
import profilePlaceholder from '../assets/profile-placeholder.svg'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import CreatePost from '../components/CreatePost';

const SharePostModal = ({onClose, children}) => {

  const [isQuotePostActive, setIsQuotePostActive] = useState(false);
  const [isViaMessageActive, setIsViaMessageActive] = useState(false);

  const toggleQuotePost = () => setIsQuotePostActive(!isQuotePostActive);
  const toggleViaMessage = () => setIsViaMessageActive(!isViaMessageActive);

  // Recipient
  const [searchName, setSearchName] = useState("");
  const [recipients, setRecipients] = useState([
    { id: 1, name: "John Doe", selected: false },
    { id: 2, name: "Jane Smith", selected: false },
    { id: 3, name: "Alice Johnson", selected: false },
    { id: 4, name: "Michael Brown", selected: false },
    { id: 5, name: "Emily Davis", selected: false },
  ]);

  // Determine recipients to display
  const displayedRecipients =
    searchName.trim() === ""
      ? recipients.slice(0, 3) // Show only first 3 recipients by default
      : recipients.filter((recipient) =>
          recipient.name.toLowerCase().includes(searchName.toLowerCase())
        )
      .slice(0, 3); // Limit search results to a maximum of 3

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchName(e.target.value);
  };

  // Toggle recipient selection
  const toggleSelection = (id) => {
    setRecipients((prevRecipients) =>
      prevRecipients.map((recipient) =>
        recipient.id === id
          ? { ...recipient, selected: !recipient.selected }
          : recipient
      )
    );
  };

  return (
    <div className="share-post-modal" onClick={onClose}>
    	<div className="share-post-container" onClick={(e) => e.stopPropagation()}>
      {children}
    		<h4 className="share-post-title-text">Share post ...</h4>


    		<div className="share-post-options">
    			<div className="option">
            <div className="option-icon">
              <img src={repostIcon} />
            </div>
            <div className="option-texts">
              <p className="option-title">Repost</p>
              <p className="option-subtitle">Share directly to your feed.</p>
            </div>
          </div>
          <div className="option-alt">
            <div className="option-alt-flex" onClick={toggleQuotePost}>
              <div className="option-icon">
                <img src={quotepostIcon} />
              </div>
              <div className="option-texts">
                <p className="option-title">Quote Repost</p>
                <p className="option-subtitle">Say something before reposting.</p>
              </div>
              {isQuotePostActive && (
              <Icon.X size={16} style={{margin: 'auto 0 auto auto'}} onClick={toggleQuotePost}/>
              )}
            </div>
            {isQuotePostActive && (
              <div>
                <hr/>
                <CreatePost />
              </div>
            )}

          </div>
          <div className="option-alt">
            <div className="option-alt-flex" onClick={toggleViaMessage}>
              <div className="option-icon">
                <img src={messageIcon} />
              </div>
              <div className="option-texts">
                <p className="option-title">Share via messages</p>
                <p className="option-subtitle">Send to a friend.</p>
              </div>
              {isViaMessageActive && (
              <Icon.X size={16} style={{margin: 'auto 0 auto auto'}} onClick={toggleViaMessage}/>
              )}
            </div>

            {isViaMessageActive && (
            <div className="recipients" style={{marginTop: '16px'}}>
              <input
                type="text"
                placeholder="Search by name"
                value={searchName}
                onChange={handleSearchChange}
              />
              <ul className="recipient-options">
                {displayedRecipients.map((recipient) => (
                  <li className="recipient-item"
                    key={recipient.id}
                  >
                    <div className="recipient-deets">
                      <div className="recipient-image">
                        <img className="recipient-userimage" src={profilePlaceholder} />
                      </div>
                      <p className="recipient-name">{recipient.name}</p>
                    </div>

                    <label className="custom-checkbox">
                      <input
                        className="checkbox"
                        type="checkbox"
                        checked={recipient.selected}
                        onChange={() => toggleSelection(recipient.id)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    
                    
                  </li>
                ))}
              </ul>
            </div>
            )}
          </div>
          <div className="option">
            <div className="option-icon">
              <img src={copylinkIcon} />
            </div>
            <div className="option-texts">
              <p className="option-title">Copy link</p>
              <p className="option-subtitle">Copy post link and share it anywhere.</p>
            </div>
          </div>
    		</div>
    	</div>
    </div>
  );
}

export default SharePostModal;