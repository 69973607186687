import React, { useEffect, useState } from 'react';
import styles from '../styles/AccountSettings.css';
import * as Icon from 'react-feather';
import Header from '../components/Header';
import userAvatar from '../assets/avatar.jpg';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import TertiaryButton from '../components/TertiaryButton';
import EditProfileInformation from '../components/EditProfileInformation';
import EditEmailPassword from '../components/EditEmailPassword';
import emailPasswordIcon from '../assets/shield-icon.svg';
import profileInfoIcon from '../assets/user-icon-white.svg';
import { auth } from '../Firebase';
import { useNavigate } from 'react-router-dom';

const EditProfile = () => {
	const [isProfileInfoActive, setIsProfileInfoActive] = useState(true);
	const [isEmailPassActive, setIsEmailPassActive] = useState(false);
	const navigate = useNavigate();

	const profileInfoActive = () => {
		setIsProfileInfoActive(true);
		setIsEmailPassActive(false);
	};

	const emailPassActive = () => {
		setIsProfileInfoActive(false);
		setIsEmailPassActive(true);
	};

	// Sign out functionality
	const handleSignOut = async () => {
		try {
			await auth.signOut();
			alert("You have successfully signed out.");
			navigate('/signin'); // Redirect to sign-in page after sign-out
		} catch (error) {
			console.error("Error signing out:", error);
			alert("An error occurred while signing out. Please try again.");
		}
	};

	return (
		<div className="account-settings">
			<Header goBack pageTitle="Account Settings" />

			<div className="account-settings-container">
				<div className="settings-side-menu">
					<p className="sidemenu-header">Settings</p>
					<hr />
					<div
						className="settings-menu-item"
						onClick={profileInfoActive}
						style={{ backgroundColor: isProfileInfoActive ? 'var(--dark-blue)' : 'transparent' }}
					>
						<img src={profileInfoIcon} className="menu-icon" alt="Profile Icon" />
						<p className="menu-title">Profile Information</p>
					</div>
					<div
						className="settings-menu-item"
						onClick={emailPassActive}
						style={{ backgroundColor: isEmailPassActive ? 'var(--dark-blue)' : 'transparent' }}
					>
						<img src={emailPasswordIcon} className="menu-icon" alt="Email Password Icon" />
						<p className="menu-title">Email and Password</p>
					</div>
					<div className="settings-menu-item" onClick={handleSignOut}>
						<img src={emailPasswordIcon} className="menu-icon" alt="Sign Out Icon" />
						<p className="menu-title">Sign Out</p>
					</div>
				</div>

				{isProfileInfoActive && (
					<div className="edit-section">
						<EditProfileInformation />
					</div>
				)}

				{isEmailPassActive && (
					<div className="edit-section">
						<EditEmailPassword />
					</div>
				)}
			</div>
		</div>
	);
};

export default EditProfile;