import styles from '../styles/CreateMessageModal.css'
import { useEffect, useState } from "react";
import * as Icon from 'react-feather';
import profilePlaceholder from '../assets/profile-placeholder.svg'
import PrimaryButton from '../components/PrimaryButton'

const CreateMessageModal = ({isVisible, onClose, children}) => {
	// Recipient
	const predefinedOptions = ['Dylan Thomas', 'Bruno Mars', 'Eminem', 'Astro'];
	const [inputRecipientValue, setInputRecipientValue] = useState('');
	const [values, setValues] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);

	const handleInputChange = (e) => {
		setInputRecipientValue(e.target.value);
		if (e.target.value.trim() !== '') {
	      setShowDropdown(true);
	    } else {
	      setShowDropdown(false); // Hide dropdown when input is empty
	    }
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter' || e.key === ',') {
		  e.preventDefault();
		  if (inputRecipientValue.trim() !== '') {
		    addValue(inputRecipientValue.trim());
		  }
		}
	};

	const addValue = (value) => {
	    if (!values.includes(value)) {
	      setValues((prevValues) => [...prevValues, value]);
	    }
	    setInputRecipientValue(''); // Clear input
	    setShowDropdown(false);
	 };

	const handleRemoveValue = (valueToRemove) => {
	setValues((prevValues) => prevValues.filter((value) => value !== valueToRemove));
	};

	const handleInputFocus = () => {
	    setShowDropdown(true);
	};

	const handleOptionClick = (option) => {
	    addValue(option);
	};

	// THIS POPUP isVisible
	if (!isVisible) return null;

	return (
		<div className="create-message-modal">
			{children}
			<div className="inner-modal" onClick={(e) => e.stopPropagation()}>
				<div className="modal-header">
					<p>Create Message</p>
					<div className="close-icon" onClick={onClose}>
						<Icon.X size={16} />
					</div>
				</div>

				<hr />

				<div className="recipient-field">
		            
			        <div className="selected-options">
				        {/* Selected options displayed as tags */}
			        	{values.length > 0 && (
			        	<div className="recipient-tags">
					          <ul className="selected-recipient-list">
					            {values.map((value, index) => (
					              <li key={index} className="selected-tags">
					                {value}
					                <button onClick={() => handleRemoveValue(value)}>
					                	<Icon.X size={16} style={{margin: '2px 0 0 0'}}/>
					                </button>
					              </li>
					            ))}
					          </ul>
					      </div>
					     )}
			        </div>
			        
			        {values.length === 0 && (
		            <input
						type="text"
						value={inputRecipientValue}
						onChange={handleInputChange}
			        	onKeyDown={handleKeyDown}
			        	onFocus={handleInputFocus}
						placeholder="Type to add recipient"
						className="multi-input-recipient input-field"
						required
		            />
		            )}

		            {/* Show dropdown when input is not empty */}
					{showDropdown && inputRecipientValue.trim() !== '' && (
					<div className="recipient-options">
						<ul className="recipient-dropdown">
						  {predefinedOptions
						    .filter((option) => option.toLowerCase().includes(inputRecipientValue.toLowerCase()) && !values.includes(option)).map((option, index) => (
						      <li key={index} onClick={() => handleOptionClick(option)} className="dropdown-item">
						        <div className="user-image-container">
						        	<img src={profilePlaceholder} className="user-image-dropdown"/>
						        </div>
						        {option}
						      </li>
						    ))}
						</ul>
					</div>
					)}
				</div>

				<div className="textarea">
					<textarea
						className="compose-message"
						placeholder="Aa"
					/>
				</div>

				<div className="send-button">
					<PrimaryButton buttonLabel="Send" />
				</div>
			</div>
		</div>
	)
}

export default CreateMessageModal;
