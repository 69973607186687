import styles from '../styles/NotificationPopup.css'
import * as Icon from 'react-feather';
import userAvatar from '../assets/avatar.jpg'
import PrimaryButton from './PrimaryButton'

const NotificationPopup = () => {

  return (
    <div className="notification-popup component">
    	<div className="container">
      	
        <div className="notification-row">

          <div className="left-aligned-content">
            <div className="notif-thumbnail">
              <img src="" />
            </div>
            <div className="notif-content">
              <p className="notif-title">
                Notification Title
              </p>
              <p className="notif-body">
                Notification Body
              </p>
            </div>
          </div>

          <div className="right-aligned-content">
            <span>12h</span>
            <div className="new-notif-indicator"></div>
          </div>
          
        </div>

        <hr />

    	</div>
    </div>
  );
}

export default NotificationPopup;
