import styles from '../styles/FeaturedSection.css'
import * as Icon from 'react-feather';
import trackPlaceholder from '../assets/track-placeholder.svg'
import merchPlaceholder from '../assets/merch-placeholder.svg'
import eventPlaceholder from '../assets/event-placeholder.svg'
import { useEffect, useState } from "react";
import TertiaryButton from '../components/TertiaryButton'
import UploadTrack from '../components/UploadTrack'
import CreateEvent from '../components/CreateEvent'
import CreateMerch from '../components/CreateMerch'
import TrackDetails from '../components/TrackDetails'
import MerchDetails from '../components/MerchDetails'

const FeaturedSection = () => {

  const [isMediaActive, setIsMediaActive] = useState(true);
  const [isMerchActive, setIsMerchActive] = useState(false);
  const [isEventsActive, setIsEventsActive] = useState(false);

  const toggleMediaTab = () => {
    setIsMediaActive(true)
    setIsMerchActive(false)
    setIsEventsActive(false)
  }

  const toggleMerchTab = () => {
    setIsMediaActive(false)
    setIsMerchActive(true)
    setIsEventsActive(false)
  }

  const toggleEventsTab = () => {
    setIsMediaActive(false)
    setIsMerchActive(false)
    setIsEventsActive(true)
  }

  const [isUploadTrackVisible, setIsUploadTrackVisible] = useState(false);
  const [isCreateEventVisible, setIsCreateEventVisible] = useState(false);
  const [isCreateMerchVisible, setIsCreateMerchVisible] = useState(false);
  const [isTrackDetailsVisible, setIsTrackDetailsVisible] = useState(false);
  const [isMerchDetailsVisible, setIsMerchDetailsVisible] = useState(false);

  const toggleUploadTrackPopup = () => {
    setIsUploadTrackVisible(!isUploadTrackVisible);
  }

  const toggleCreateMerchPopup = () => {
    setIsCreateMerchVisible(!isCreateMerchVisible);
  }

  const toggleCreateEventPopup = () => {
    setIsCreateEventVisible(!isCreateEventVisible);
  }

  const toggleTrackDetailsPopup = () => {
    setIsTrackDetailsVisible(!isTrackDetailsVisible);
  }

  const toggleMerchDetailsPopup = () => {
    setIsMerchDetailsVisible(!isMerchDetailsVisible);
  }

  return (
    <div className="featured-section-component">
    	<div className="featured-section-container">

    		<div className="featured-tabs">
    			<div className="featured-tab-item" onClick={toggleMediaTab}>
            <p style={{ color: isMediaActive ? 'var(--acc-blue)' : 'var(--text-grey)' }}>Media/Music</p>
            <div className="tab-underline" style={{ backgroundColor: isMediaActive ? 'var(--acc-blue)' : 'transparent' }}></div>
    			</div>
          <div className="featured-tab-item" onClick={toggleMerchTab}>
            <p style={{ color: isMerchActive ? 'var(--acc-blue)' : 'var(--text-grey)' }}>Merchandise</p>
            <div className="tab-underline" style={{ backgroundColor: isMerchActive ? 'var(--acc-blue)' : 'transparent' }}></div>
          </div>
          <div className="featured-tab-item" onClick={toggleEventsTab}>
            <p style={{ color: isEventsActive ? 'var(--acc-blue)' : 'var(--text-grey)' }}>Events</p>
            <div className="tab-underline" style={{ backgroundColor: isEventsActive ? 'var(--acc-blue)' : 'transparent' }}></div>
          </div>
    		</div>

        {/* MEDIA/MUSIC TAB */}
        { isMediaActive && (
          <div className="media-music">

            {/* ONLY SHOW ON OWN USER'S PROFILE */}
            <div className="upload-track" onClick={toggleUploadTrackPopup}>
              <div className="icon">
                <Icon.Music size={18} color = "#0094D4"/>
              </div>
              <p className="">Upload new track</p>

            </div>
            
            {/* LIST OF TRACKS */}
            <div className="track-item">
              <div className="track-thumbnail" onClick={toggleTrackDetailsPopup}>
                <img src={trackPlaceholder} />
              </div>
              <div className="track-details">
                <p className="track-title">Track Title</p>
                <p className="track-album-year">Year</p>
              </div>
              <div className="play-button">
                <Icon.Play size={18} />
              </div>
            </div>

          </div>
        )}

        {/* MEDIA/MUSIC TAB */}
        { isMerchActive && (
          <div className="merch">

            {/* ONLY SHOW ON OWN USER'S PROFILE */}
            <div className="upload-merch" onClick={toggleCreateMerchPopup}>
              <div className="icon">
                <Icon.PlusSquare size={18} color = "#0094D4"/>
                <p className="">Create a listing</p>

              </div>
            </div>


            {/* LIST OF MERCH */}
            <div className="merch-item" onClick={toggleMerchDetailsPopup}>
              <div className="merch-thumbnail">
                <img src={merchPlaceholder} />
              </div>
              <div className="merch-details">
                <h3 className="merch-title">item name</h3>
                <p className="merch-description">His palms are sweaty, knees weak, arms are heavy
                  There's vomit on his sweater already, mom's spaghetti
                  He's nervous, but on the surface, he looks calm and ready
                  To drop bombs, but he keeps on forgetting
                  What he wrote down, the whole crowd goes so loud
                  He opens his mouth, but the words won't come out </p>
              </div>
            </div>
          </div>
        )}

        {/* MEDIA/MUSIC TAB */}
        { isEventsActive && (
          <div className="events">

            {/* ONLY SHOW ON OWN USER'S PROFILE */}
            <div className="create-event" onClick={toggleCreateEventPopup}>
              <div className="icon-text">
                <div className="icon">
                  <Icon.Calendar size={18} color = "#0094D4" />
                </div>
                <p>Create Event</p>
              </div>
            </div>

            {/* LIST OF THUMBNAILS */}
            <div className="event-item">
              <div className="event-thumbnail">
                <img src={eventPlaceholder} />
              </div>
              <div className="event-details">
                <h3 className="event-title">Event name</h3>
                <p className="event-date-loc">
                  <div className="event-date sub-details">
                    <span>
                      <Icon.Calendar size={11} style={{margin: 'auto'}}/>
                    </span>
                    <span className="date-text" style={{fontSize: 'var(--text-md)', margin: 'auto 4px'}}>
                      Date
                    </span>
                  </div>
                  • 

                  {/* Hide location if empty */}
                  <div className="event-location sub-details">
                    <span>
                      <Icon.MapPin size={11} style={{margin: 'auto'}}/>
                    </span>
                    <span className="date-text" style={{fontSize: 'var(--text-md)', margin: 'auto 4px'}}>
                      Location
                    </span>
                  </div>

                  • 

                  <div className="event-ticket sub-details">
                    <span>
                      <Icon.DollarSign size={11} style={{margin: 'auto'}}/>
                    </span>
                    <span className="date-text" style={{fontSize: 'var(--text-md)', margin: 'auto 4px'}}>
                      Free Admission
                    </span>
                  </div>
                </p>
                <p className="event-description">
                  His palms are sweaty, knees weak, arms are heavy
                  There's vomit on his sweater already, mom's spaghetti
                  He's nervous, but on the surface, he looks calm and ready
                  To drop bombs, but he keeps on forgetting
                  What he wrote down, the whole crowd goes so loud
                  He opens his mouth, but the words won't come out 
                  His palms are sweaty, knees weak, arms are heavy
                  There's vomit on his sweater already, mom's spaghetti
                  He's nervous, but on the surface, he looks calm and ready
                  To drop bombs, but he keeps on forgetting
                  What he wrote down, the whole crowd goes so loud
                  He opens his mouth, but the words won't come out 
                  His palms are sweaty, knees weak, arms are heavy
                  There's vomit on his sweater already, mom's spaghetti
                  He's nervous, but on the surface, he looks calm and ready
                  To drop bombs, but he keeps on forgetting
                  What he wrote down, the whole crowd goes so loud
                  He opens his mouth, but the words won't come out 
                </p>
                <p className="event-date-posted">Posted 1mo ago</p>

              </div>

            </div>

          </div>
        )}
    	</div>

      {/* OVERLAYS / POPUPS */}

      <div className="overlays">
          <UploadTrack  isVisible={isUploadTrackVisible} onClose={toggleUploadTrackPopup}/>
          <CreateEvent  isVisible={isCreateEventVisible} onClose={toggleCreateEventPopup}/>
          <CreateMerch  isVisible={isCreateMerchVisible} onClose={toggleCreateMerchPopup}/>

          <TrackDetails  isVisible={isTrackDetailsVisible} onClose={toggleTrackDetailsPopup}/>
          <MerchDetails  isVisible={isMerchDetailsVisible} onClose={toggleMerchDetailsPopup}/>
      </div>
    </div>
  );
}

export default FeaturedSection;
