import styles from '../styles/BubblePill.css'


const BubblePill = ({bubbleText}) => {
  return (
    <div className="bubble-pill">
      <span className="bubble-text">{bubbleText}</span>
    </div>
  );
}

export default BubblePill;
