import styles from '../styles/DiscoverPeopleSlider.css'
import UserCard from '../components/UserCard'


const DiscoverPeopleSlider = () => {

  return (
    <div className="discover-people-slider component">
    	<div className="container">
      	<h5>Discover People</h5>

      	{/* LIST ALL SUGGESTIONS */}
      	<div className="user-cards">
          <UserCard />
          <UserCard />
          <UserCard />
          <UserCard />
          <UserCard />
          <UserCard />
          <UserCard />
          <UserCard />
          <UserCard />
          <UserCard />
      		<UserCard />
      	</div>
    	</div>
    </div>
  );
}

export default DiscoverPeopleSlider;
