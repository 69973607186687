import styles from '../styles/UploadTrack.css';
import * as Icon from 'react-feather';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';

const UploadTrack = ( {isVisible, onClose, children}) => {

	// Predefined list of options for artists
	const options = ['John Doe', 'Dylan Thomas', 'Patty Smith', 'Charlie Puth'];
	const genreOptions = ["Dance & Electronic", "Hauntology", "Hiphop", "R&B"];
	const subgenreOptions = ["Dance & Electronic", "Hauntology", "Hiphop", "R&B"];

	// Genre
	const [selectedGenreOptions, setSelectedGenreOptions] = useState("");
	const handleGenreChange = (event) => {
		setSelectedGenreOptions(event.target.value);
	};

	// Sub-genre
	const [selectedSubgenreOptions, setSelectedSubgenreOptions] = useState("");
	const handleSubgenreChange = (event) => {
		setSelectedSubgenreOptions(event.target.value);
	};


	// THUMBNAIL UPLOAD
	const [preview, setPreview] = useState(null);

	const handleThumbnailChange = (event) => {
	const thumbnail = event.target.files[0];
	if (thumbnail) {
		const reader = new FileReader();
			reader.onloadend = () => {
			setPreview(reader.result); // Set the thumbnail preview
		};
		  reader.readAsDataURL(thumbnail); // Read the thumbnail as a Data URL
		} else {
		  	setPreview(null); // Reset preview if no thumbnail
		}
	};

	// AUDIO FILE UPLOAD
	const [audio, setAudio] = useState(null);

	const handleAudiohange = (event) => {
		const audioFile = event.target.files[0];
	    if (audioFile) {
	      setAudio(audioFile.name); // Set the audio audioFile name
	    } else {
	      setAudio(null); // Reset the audioFile name if no audioFile is selected
	    }
	};

	
	// ARTIST
	const predefinedOptions = ['Dylan Thomas', 'Bruno Mars', 'Eminem', 'Astro'];
	const [inputArtistValue, setInputArtistValue] = useState('');
	const [values, setValues] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);

	const handleInputChange = (e) => {
		setInputArtistValue(e.target.value);
		if (e.target.value.trim() !== '') {
	      setShowDropdown(true);
	    } else {
	      setShowDropdown(false); // Hide dropdown when input is empty
	    }
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter' || e.key === ',') {
		  e.preventDefault();
		  if (inputArtistValue.trim() !== '') {
		    addValue(inputArtistValue.trim());
		  }
		}
	};

	const addValue = (value) => {
	    if (!values.includes(value)) {
	      setValues((prevValues) => [...prevValues, value]);
	    }
	    setInputArtistValue(''); // Clear input
	    setShowDropdown(false);
	 };

	const handleRemoveValue = (valueToRemove) => {
	setValues((prevValues) => prevValues.filter((value) => value !== valueToRemove));
	};

	const handleInputFocus = () => {
	    setShowDropdown(true);
	};

	const handleOptionClick = (option) => {
	    addValue(option);
	};

	// THIS POPUP isVisible
	if (!isVisible) return null;

	return (

		<div className="upload-track-overlay">
			<div className="upload-track-card" onClick={(e) => e.stopPropagation()}>
				{children}
				<div className="upload-track-header">
					<p className="overlay-title">Upload Track</p>
					<div className="close-overlay-button" onClick={onClose}>
						<Icon.X color='#fff' size={18} />
					</div>
				</div>

				<hr />

				<form className="upload-track-form">
					<div className="input-fields-section">

						<div className="media-uploader form-field">
							<label className="label"> Track Thumbnail </label>
							<div className="upload-thumbnail">
								{preview && (
								<div className="thumbnail-preview">
									<img className="image-preview" src={preview} alt="Image Preview" />
								</div>
								)}
								<div className="file-uploader">
									<div className="uploader-icon">
										<Icon.Image size={32} color="var(--acc-blue)"/>
									</div>
									<label for="track-thumbnail" className="clickable-label">Click here to upload the track's cover art</label>
									<input 
										id="track-thumbnail" 
										type="file" 
										onChange={handleThumbnailChange}
        								accept="image/*"
										style={{display: 'none'}}
										required
									/>
								</div>

							</div>
							

						</div>

						<div className="form-track-title form-field">
				            <label for="track-title" className="label">
				            	Track Title
				            	<span className="required-asterisk"> * </span>
				            </label>
				            <input
				            	id="track-title"
								placeholder="Track Title"
								required
								className="input-field"
				            />
				         </div>

				        <div className="form-track-artist form-field">
				            <label className="label">
				            	Artist
				            	<span className="required-asterisk"> * </span>
				            </label>
				            
					        <div className="selected-options">
						        {/* Selected options displayed as tags */}
					        	{values.length > 0 && (
					        	<div className="artists-tags">
							          <ul className="selected-artists-list">
							            {values.map((value, index) => (
							              <li key={index} className="selected-tags">
							                {value}
							                <button onClick={() => handleRemoveValue(value)}>
							                	<Icon.X size={16} style={{margin: '2px 0 0 0'}}/>
							                </button>
							              </li>
							            ))}
							          </ul>
							      </div>
							     )}
					        </div>
					        
				            <input
								type="text"
								value={inputArtistValue}
								onChange={handleInputChange}
					        	onKeyDown={handleKeyDown}
					        	onFocus={handleInputFocus}
								placeholder="Type to add artists"
								className="multi-input-artists input-field"
								required
				            />
				            <p className="subtext">Separate artists with comma.</p>

				            {/* Show dropdown when input is not empty */}
							{showDropdown && inputArtistValue.trim() !== '' && (
							<div className="artist-options">
								<ul className="artist-dropdown">
								  {predefinedOptions
								    .filter((option) => option.toLowerCase().includes(inputArtistValue.toLowerCase()) && !values.includes(option)).map((option, index) => (
								      <li key={index} onClick={() => handleOptionClick(option)} className="dropdown-item">
								        {option}
								      </li>
								    ))}
								</ul>
							</div>
							)}

				        </div>

				        <div className="form-track-release-year form-field">
				            <label className="label">
				            	Released Year
				            	<span className="required-asterisk"> * </span>
				            </label>
				            <input
				              type=""
					          placeholder="Enter released year"
					          className="input-field"
					          required
				            />
				        </div>

				        <div className="form-track-uploader form-field">
				            <label className="label">
				            	Upload a track file
				            	<span className="required-asterisk"> * </span>
				            </label>
				            <div className="upload-audio">
								{audio && (
								<div className="audio-file">
									<Icon.CheckCircle size={14} color="var(--acc-blue)" style={{margin: 'auto 0'}}/>
									<p className="audio-file-name"> {audio} </p>
								</div>
								)}
								<div className="file-uploader">
									<div className="uploader-icon">
										<Icon.Music size={32} color="var(--acc-blue)"/>
									</div>
									<label for="audio-file" className="clickable-label">Click here to upload a track file</label>
									<input 
										id="audio-file" 
										type="file" 
										onChange={handleAudiohange}
        								accept="audio/*"
										style={{display: 'none'}}
									/>
								</div>

							</div>
				        </div>

				        <div className="form-genre form-field">
				            <label className="label">
				            	Genre
				            	<span className="required-asterisk"> * </span>
				            </label>
				            <select className="dropdown-genre">
				           		<option value="" hidden>Select a genre</option>
						        {/* Dynamically create option elements from array */}
						        {genreOptions.map((option, index) => (
						        	<option key={index} value={option} className="genre-options-list" required>
										{option}
									</option>
						        ))}
				            </select>
				        </div>

				        {/* Disable when no subgenre under the main genre */}
				        <div className="form-subgenre form-field">
				            <p className="label">Sub-genre</p>
				            <select className="dropdown-genre">
				           		<option value="" hidden>Select a sub-genre</option>
						        {/* Dynamically create option elements from array */}
						        {subgenreOptions.map((option, index) => (
						        	<option key={index} value={option} className="subgenre-options-list" required>
										{option}
									</option>
						        ))}
				            </select>
				        </div>


				        {/* Disable when no subgenre under the previous subgenre */}
				        <div className="form-subgenre form-field">
				            <p className="label">Sub-subgenre</p>
				            <select className="dropdown-genre">
				           		<option value="" hidden>Select a sub-subgenre</option>
						        {/* Dynamically create option elements from array */}
						        {subgenreOptions.map((option, index) => (
						        	<option key={index} value={option} className="subgenre-options-list" required>
										{option}
									</option>
						        ))}
				            </select>
				        </div>

				        <div className="form-buttons">
							
							<PrimaryButton buttonLabel="Upload" className="button" />
						</div>

					</div>
				</form>

			</div>
		</div>

	)
}

export default UploadTrack;