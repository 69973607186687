import styles from '../styles/CreatePost.css';
import * as Icon from 'react-feather';
import BubblePill from './BubblePill';
import profilePlaceholder from '../assets/profile-placeholder.svg';
import { useEffect, useState } from "react";
import CreatePostPopup from '../components/CreatePostPopup';
import PrimaryButton from '../components/PrimaryButton';
import { db, auth } from '../Firebase';
import { collection, addDoc, serverTimestamp, getDoc, doc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

const CreatePost = () => {
  const [isPostPopupVisible, setIsPostPopupVisible] = useState(false);
  const [content, setContent] = useState('');
  const [userID, setUserID] = useState(null);
  const [userHandle, setUserHandle] = useState('@username');
  const [profilePic, setProfilePic] = useState(profilePlaceholder); // Initialize with placeholder image

  const togglePopup = () => {
    setIsPostPopupVisible(!isPostPopupVisible);
  };

  useEffect(() => {
    const fetchUserData = async (uid) => {
      try {
        const userDocRef = doc(db, 'users', uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserHandle(`@${userData.handle || 'username'}`);
          setProfilePic(userData.profilePic || profilePlaceholder); // Use profilePic if available, else placeholder
          console.log('User handle fetched:', userData.handle);
          console.log('User profile pic:', userData.profilePic);
        } else {
          console.warn('User document does not exist');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserID(user.uid); 
        fetchUserData(user.uid); 
      } else {
        console.warn('No authenticated user found');
      }
    });

    return () => unsubscribe();
  }, []);

  const handlePost = async () => {
    console.log('handlePost called');

    if (!content.trim()) {
      alert('Please enter some content before posting.');
      return;
    }

    if (!userID) {
      console.error('User ID is not set'); 
      return;
    }

    console.log('Content:', content); 
    console.log('User ID:', userID); 

    try {
      const postRef = await addDoc(collection(db, 'posts'), {
        userID: userID,
        content: content,
        mediaType: 'text',
        mediaURL: '',
        timestamp: serverTimestamp(),
        comments: '',
        likes: '', 
      });

      console.log('Post created successfully:', postRef.id); 
      alert('Post created successfully!');
      setContent(''); 
    } catch (error) {
      console.error('Error creating post:', error); 
      alert('Error creating post. Please try again.');
    }
  };

  return (
    <div className="create-post component">
      <div className="container">
        <div className="image-text">
          <div className="user-image">
            <img src={profilePic} alt="User Profile" /> {/* Display fetched profilePic */}
          </div>
          <div className="content">
            <p className="user-handle">{userHandle}</p>
            <textarea
              className="post-content"
              placeholder="What's up?"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <div className="post-actions">
              <div className="add-content">
                <Icon.Image color='#0094D4' size={24} style={{ margin: 'auto 0' }}/>
                <BubblePill bubbleText="+ Add Bubble"/>
              </div>
              <div className="btn">
                <PrimaryButton 
                  buttonLabel="POST" 
                  onClick={() => {
                    console.log("I got clicked");
                    handlePost();
                  }} 
                />
              </div>
            </div>

            
          </div>
        </div>
        
      </div>

      {/* POPUP */}
      <CreatePostPopup isVisible={isPostPopupVisible} onClose={togglePopup}/>
    </div>
  );
}

export default CreatePost;