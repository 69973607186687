import styles from '../styles/MerchDetails.css';
import * as Icon from 'react-feather';
import merchPlaceholder from '../assets/merch-placeholder.svg'
import { useEffect, useState } from "react";
import BubblePill from '../components/BubblePill'
import { Link } from 'react-router-dom';

const MerchDetails = ( {isVisible, onClose, children}) => {

	// THUMBNAIL UPLOAD
	const [preview, setPreview] = useState(null);

	if (!isVisible) return null;


	return(
		<div className="merch-details-overlay" onClick={onClose}>
			<div className="merch-details-card" onClick={(e) => e.stopPropagation()}>
				{children}
				<div className="merch-details-content"> 
					<div className="merch-thumbnail">
						<img src={merchPlaceholder} />
					</div>

					<div className="merch-details">
						<h3> Item Name </h3>

						<p className="merch-description">
							His palms are sweaty, knees weak, arms are heavy
							There's vomit on his sweater already, mom's spaghetti
							He's nervous, but on the surface, he looks calm and ready
							To drop bombs, but he keeps on forgetting
							What he wrote down, the whole crowd goes so loud
							He opens his mouth, but the words won't come out 
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MerchDetails;