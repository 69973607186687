import styles from '../styles/PrimaryButton.css';

const PrimaryButton = ({ buttonLabel, type, disabled, onClick }) => {
  return (
    <div>
      <button
        className="primary-button"
        type={type}
        disabled={disabled}
        onClick={onClick}  // Add onClick prop here
      >
        {buttonLabel}
      </button>
    </div>
  );
};

export default PrimaryButton;